// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout, PageView } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

// eslint-disable-next-line no-unused-vars
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '综保科技' },
    redirect: '/dashboard',
    children: [
      // dashboard
      /* {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/analysis',
        component: PageView,
        meta: { title: '主页', keepAlive: true, icon: 'apple', permission: [ 'dashboard' ] },
        children: [
          {
            path: '/dashboard/analysis/:pageNo([1-9]\\d*)?',
            name: 'Analysis',
            component: () => import('@/views/dashboard/emputy'),
            meta: { title: '主页', keepAlive: false, permission: [ 'dashboard' ] }
          }/!*,
          // 外部链接
          {
            path: 'https://www.baidu.com/',
            name: 'Monitor',
            meta: { title: 'menu.dashboard.monitor', target: '_blank' }
          },
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: 'menu.dashboard.workplace', keepAlive: true, permission: [ 'dashboard' ] }
          } *!/
        ]
      }, */
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/workplace',
        component: PageView,
        meta: { title: '主页', keepAlive: true, icon: 'dashboard' },
        children: [
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: '快捷导航', keepAlive: true }
          }
        ]
      },
      // a-corporation
      {
        path: '/corporation',
        name: 'corporation',
        redirect: '/corporation/CorporationInformation',
        component: PageView,
        meta: { title: '企业', keepAlive: true, icon: bxAnaalyse, permission: [ 'corporation', 'tag' ] },
        children: [
          {
            path: '/corporation/CorporationInformation',
            name: 'CorporationInformation',
            component: () => import('@/views/a-corporation/CorporationInformation'),
            meta: { title: '企业', keepAlive: false, permission: [ 'corporation' ] }
          },
          {
            path: '/corporation/CorporationInformation/:id',
            name: 'CorporationInformationEdit',
            hidden: true,
            component: () => import('@/views/a-corporation/table/Edit'),
            meta: { title: '企业', keepAlive: false, permission: [ 'corporation' ] }
          },
          {
            path: '/tag/TagInformation',
            name: 'TagInformation',
            component: () => import('@/views/a-tag/TagInformation'),
            meta: { title: '标签', keepAlive: false, permission: [ 'tag' ] }
          },
          {
            path: '/tag/TagInformation/:id',
            name: 'TagInformationEdit',
            hidden: true,
            component: () => import('@/views/a-tag/table/Edit'),
            meta: { title: '标签', keepAlive: false, permission: [ 'tag' ] }
          },
          {
            path: '/Undertaking/Undertaking',
            name: 'Undertaking',
            component: () => import('@/views/a-inout/Undertaking'),
            meta: { title: '承诺书', keepAlive: false, permission: [ 'undertaking' ] }
          }
        ]
      },
      {
        path: '/account/settings',
        component: PageView,
        redirect: '/account/settings/base',
        hidden: true,
        name: 'account',
        meta: { title: '个人页', icon: 'user', keepAlive: true, permission: [ 'user_settings' ] },
        children: [
          {
            path: '/account/settings/base',
            name: 'settings',
            component: () => import('@/views/account/settings/BaseSetting'),
            meta: { title: '个人设置', hideHeader: true, permission: [ 'user_settings' ] }
          }
        ]
      },
      // {
      //   path: '/inventory',
      //   name: 'inventory',
      //   redirect: '/inventory/Inventory',
      //   component: PageView,
      //   meta: { title: '仓库', keepAlive: true, icon: 'appstore', permission: [ 'inventory' ] },
      //   children: [
      //     {
      //       path: '/inventory/Inventory',
      //       name: 'Inventory',
      //       component: () => import('@/views/a-inventory/Inventory'),
      //       meta: { title: '仓库管理', keepAlive: true, permission: [ 'inventory' ] }
      //     }
      //   ]
      // },
      {
        path: '/users',
        name: 'Users',
        redirect: '/users/userList',
        component: PageView,
        meta: { title: '用户', keepAlive: true, icon: 'user', permission: [ 'user_information', 'role', 'dingding' ] },
        children: [
          {
            path: '/users/userList',
            name: 'User',
            redirect: '/users/userList/UserInformation',
            component: RouteView,
            meta: { title: '用户管理', keepAlive: false, permission: ['user_information', 'role'] },
            children: [
              {
                path: '/users/userList/UserInformation',
                name: 'UserInformation',
                component: () => import('@/views/a-user/UserInformation'),
                meta: { title: '用户列表', keepAlive: false, permission: [ 'user_information' ] }
              },
              {
                path: '/users/userList/UserInformation/:id',
                name: 'UserInformationEdit',
                hidden: true,
                component: () => import('@/views/a-user/table/Edit'),
                meta: { title: '用户编辑', keepAlive: false, permission: [ 'user_information' ] }
              },
              {
                path: '/users/userList/RoleInformation',
                name: 'RoleList',
                component: () => import('@/views/a-role/RoleInformation'),
                meta: { title: '权限组配置', keepAlive: false, permission: [ 'role' ] }
              },
              {
                path: '/users/userList/RoleInformation/:id',
                name: 'RoleListEdit',
                hidden: true,
                component: () => import('@/views/a-role/table/Edit'),
                meta: { title: '权限组配置编辑', keepAlive: false, permission: [ 'role' ] }
              }
            ]
          },
          {
            path: '/users/dingding',
            name: 'dingding',
            redirect: '/users/dingding/dept',
            component: RouteView,
            meta: { title: '钉钉', keepAlive: true, permission: [ 'dingding' ] },
            children: [
              {
                path: '/users/dingding/dept',
                name: 'DingdingDept',
                component: () => import('@/views/a-dingding_dept/DingDingDept'),
                meta: { title: '钉钉部门', keepAlive: true }
              },
              {
                path: '/users/dingding/user',
                name: 'DingdingUser',
                component: () => import('@/views/a-dingding_user/DingDingUser'),
                meta: { title: '钉钉用户', keepAlive: true }
              }
            ]
          },
          {
            path: '/users/approval',
            name: 'Approval',
            redirect: '/users/approval/templates',
            component: RouteView,
            meta: { title: '审批', keepAlive: true, permission: ['approve_process'] },
            children: [
              {
                path: '/users/approval/templates',
                name: 'ApprovalTemplates',
                component: () => import('@/views/a-approve/admin/FormsPanel'),
                meta: { title: '流程模板', permission: ['approve_process'] }
              },
              {
                path: '/users/approval/templates/create',
                name: 'ApprovalTemplatesCreate',
                hidden: true,
                component: () => import('@/views/a-approve/admin/FormProcessDesign'),
                meta: { title: '流程模板创建', permission: ['approve_process'] }
              },
              {
                path: '/users/approval/todo',
                name: 'ApprovalToDo',
                component: () => import('@/views/a-approve/admin/ShenPiToDoList'),
                meta: { title: '我的审批' }
              },
              {
                path: '/users/approval/todo/:id',
                name: 'ApprovalToDoDetail',
                hidden: true,
                component: () => import('@/views/a-approve/workspace/process/ProcessInstanceTabs'),
                meta: { title: '审批详情' }
              },
              {
                path: '/users/approval/todo_all',
                name: 'ApprovalTodoAll',
                component: () => import('@/views/a-approve/admin/ShenPiToDoAllList'),
                meta: { title: '全部审批' }
              },
              {
                path: '/users/approval/todo_bill/:id',
                name: 'ApprovalToDoBill',
                hidden: true,
                component: () => import('@/views/a-approve/admin/ShenPiToDoOneBill'),
                meta: { title: '审批记录' }
              }
            ]
          }
        ]
      },
      {
        path: '/property',
        name: 'Property',
        redirect: '/zone_property/index',
        component: PageView,
        meta: { title: '资产', keepAlive: true, icon: 'property-safety', permission: [ 'zone_property', 'electricity_meter', 'water_meter', 'water_meter_record', 'contract_information', 'contract_template', 'water_fees', 'electricity_fees', 'property_fees', 'rent_fees', 'administration', 'inout_application', 'inout_book', 'gateway', 'muck', 'temporary', 'property_project', 'property_project_item' ] },
        children: [
          {
            path: '/zone_property',
            name: 'zone_property',
            redirect: '/zone_property/index',
            component: RouteView,
            meta: { title: '资产', keepAlive: true, permission: [ 'zone_property', 'property_project', 'property_project_item' ] },
            children: [
              {
                path: '/zone_property/index',
                name: 'ZoneProperty',
                component: () => import('@/views/a-property/Property'),
                meta: { title: '资产信息', keepAlive: false, permission: [ 'zone_property' ] }
              },
              {
                path: '/zone_property/index/:id',
                name: 'ZonePropertyEdit',
                hidden: true,
                component: () => import('@/views/a-property/table/Edit'),
                meta: { title: '资产信息编辑', keepAlive: false, permission: [ 'zone_property' ] }
              },
              {
                path: '/zone_property_cell/index/:id',
                name: 'ZonePropertyCell',
                hidden: true,
                component: () => import('@/views/a-property/cell/PropertyCell'),
                meta: { title: '资产明细', keepAlive: false, permission: [ 'zone_property' ] }
              },
              {
                path: '/cavancy_rate/index/',
                name: 'CavancyRate',
                component: () => import('@/views/a-property/cavancy_rate/CavancyRate'),
                meta: { title: '资产统计', keepAlive: false, permission: [ 'zone_property' ] }
              },
              {
                path: '/property/summary_statistics',
                name: 'PropertySummaryStatistics',
                component: () => import('@/views/a-property/summary_statistics/Statistics'),
                meta: { title: '统计结果', keepAlive: false }
              },
              {
                path: '/asset_rental_rate/summary_statistics',
                name: 'AssetRentalRateSummaryStatistics',
                component: () => import('@/views/a-property/summary_statistics/AssetRentalRate'),
                meta: { title: '资产出租率', keepAlive: false }
              },
              {
                path: '/property_project/index',
                name: 'PropertyProject',
                component: () => import('@/views/a-property-project/PropertyProject'),
                meta: { title: '项目', keepAlive: false, permission: [ 'property_project' ] }
              },
              {
                path: '/property_project/index/:id',
                name: 'PropertyProjectEdit',
                hidden: true,
                component: () => import('@/views/a-property-project/table/Edit'),
                meta: { title: '编辑项目', keepAlive: false, permission: [ 'property_project' ] }
              },
              {
                path: '/property_project/:id',
                name: 'PropertyProjectCell',
                hidden: true,
                component: () => import('@/views/a-property-project/cell/PropertyCell'),
                meta: { title: '资产项目条目', keepAlive: false, permission: [ 'property_project_item' ] }
              },
              {
                path: '/property_project/:id/addItem',
                name: 'PropertyProjectAddItem',
                hidden: true,
                component: () => import('@/views/a-property-project/cell/table/AddItem'),
                meta: { title: '新建条目', keepAlive: false, permission: [ 'property_project_item' ] }
              }
              ]
          },
          {
            path: '/electricity_meter',
            name: 'electricity_meter',
            redirect: '/electricity_meter/index',
            component: RouteView,
            meta: { title: '电表', keepAlive: true, permission: ['electricity_meter', 'electricity_meter_balance'] },
            children: [
              {
                path: '/electricity_meter/group',
                name: 'ElectricityMeterGroup',
                component: () => import('@/views/a-electricity-meter_group/Group'),
                meta: { title: '电表组', keepAlive: false, permission: ['electricity_meter'] }
              },
              {
                path: '/electricity_meter/group/record',
                name: 'ElectricityMeterGroupRecord',
                component: () => import('@/views/a-electricity-meter_group_record/GroupRecord'),
                meta: { title: '电表组记录', keepAlive: false, permission: ['electricity_meter'] }
              },
              {
                path: '/electricity_meter_list',
                name: 'ElectricityMeter',
                component: () => import('@/views/a-electricity-meter/ElectricityMeter'),
                meta: { title: '电表列表', keepAlive: false, permission: ['electricity_meter'] }
              },
              {
                path: '/electricity_meter/:id',
                name: 'ElectricityMeterSingle',
                hidden: true,
                component: () => import('@/views/a-electricity-meter/table/SingleElectricityMeter'),
                meta: { title: '电表详情', keepAlive: false, permission: ['electricity_meter_balance'] }
              },
              {
                path: '/electricity_meter_record',
                name: 'ElectricityMeterRecord',
                component: () => import('@/views/a-electricity-meter_record/ElectricityRecord'),
                meta: { title: '抄表记录', keepAlive: false, permission: ['electricity_meter'] }
              },
              {
                path: '/electricity_meter_record/:id',
                name: 'ElectricityMeterRecordDetail',
                hidden: true,
                component: () => import('@/views/a-electricity-meter_record/table/ElectricityDetailRecord'),
                meta: { title: '抄表记录', keepAlive: false, permission: ['electricity_meter'] }
              }
            ]
          },
          {
            path: '/water_meter',
            name: 'water_meter',
            redirect: '/water_meter/index',
            component: RouteView,
            meta: { title: '水表', keepAlive: true, permission: [ 'water_meter', 'water_meter_record' ] },
            children: [
              {
                path: '/water_meter/index',
                name: 'WaterMeter',
                component: () => import('@/views/a-water_meter/WaterMeter'),
                meta: { title: '水表管理', keepAlive: false, permission: [ 'water_meter' ] }
              },
              {
                path: '/water_meter/record',
                name: 'WaterMeterRecord',
                component: () => import('@/views/a-water_meter/WaterMeterRecord'),
                meta: { title: '抄表记录', keepAlive: false, permission: [ 'water_meter_record' ] }
              },
              {
                path: '/water_meter/record/:id',
                name: 'WaterMeterRecordDetail',
                hidden: true,
                component: () => import('@/views/a-water_meter/table/RecordDetailList'),
                meta: { title: '抄表记录', keepAlive: false, permission: [ 'water_meter_record' ] }
              }
            ]
          },
          {
            path: '/contract',
            name: 'contract',
            redirect: '/contract/ContractInformation',
            component: RouteView,
            meta: { title: '合同', keepAlive: false, permission: [ 'contract_information', 'contract_template' ] },
            children: [
              {
                path: '/rent_contract/index/:id?',
                name: 'RentContract',
                component: () => import('@/views/a-rent_contract/RentContract'),
                meta: { title: '合同信息', keepAlive: false, permission: [ 'contract_information' ] }
              },
              {
                path: '/contract/index/:id?',
                name: 'ContractList',
                hidden: true,
                component: () => import('@/views/a-rent_contract/table/ContractList'),
                meta: { title: '合同信息表', keepAlive: false, permission: [ 'contract_information' ] }
              },
              {
                path: '/rent_contract/:id/zone_property_cell/',
                name: 'RentContractProperties',
                hidden: true,
                component: () => import('@/views/a-rent_contract/table/PropertiesList'),
                meta: { title: '合同资产详情', keepAlive: false, permission: [ 'contract_information' ] }
              },
              {
                path: '/rent_contract/create',
                name: 'RentContractCreate',
                hidden: true,
                component: () => import('@/views/a-rent_contract/modules/CreateForm'),
                meta: { title: '新建合同', keepAlive: false, permission: [ 'contract_information' ] }
              },
              {
                path: '/contract/ContractInformation',
                name: 'ContractInformation',
                component: () => import('@/views/a-contract/ContractInformation'),
                meta: { title: '合同模板', keepAlive: false, permission: [ 'contract_template' ] }
              },
              {
                path: '/contract/ContractInformation/:id',
                name: 'ContractInformationWatch',
                hidden: true,
                component: () => import('@/views/a-contract/table/Read'),
                meta: { title: '合同模板', keepAlive: false, permission: [ 'contract_template' ] }
              },
              {
                path: '/rent_contract/attachment/:id',
                name: 'attachment',
                hidden: true,
                component: () => import('@/views/a-rent_contract/modules/CreateFuJianForm'),
                meta: { title: '附件', keepAlive: false }
              },
              {
                path: '/contract/ContractBankAccountInformation',
                name: 'ContractBankAccountInformation',
                hidden: true,
                component: () => import('@/views/a-bank-account/BankAccountInformation'),
                meta: { title: '打款账户', keepAlive: false, permission: [ 'bank_payment_account' ] }
              }
            ]
          },
          {
            path: '/bill',
            name: 'bill',
            redirect: '/electricity/payment',
            component: RouteView,
            meta: { title: '账单', keepAlive: true, permission: [ 'water_fees', 'electricity_fees', 'property_fees', 'rent_fees', 'bill_check', 'public_area_fees', 'guarantee_fees', 'urban_land_use_tax' ] },
            children: [
              {
                path: '/property/arrears',
                name: 'PropertyArrears',
                component: () => import('@/views/a-property/ears/List'),
                meta: { title: '企业欠款', keepAlive: false }
              },
              {
                path: '/electricity/prepaybill',
                name: 'ElectricityPrepaybill',
                component: () => import('@/views/a-electricity_prepaybill/PrepayBill'),
                meta: { title: '预付电费账单', keepAlive: false, permission: [ 'electricity_fees' ] }
              },
              {
                path: '/electricity/payment/:id?',
                name: 'Electricity',
                component: () => import('@/views/a-electricity/Electricity'),
                meta: { title: '电费账单', keepAlive: false, permission: [ 'electricity_fees' ] }
              },
              {
                path: '/water/index/:id?',
                name: 'Water',
                component: () => import('@/views/a-water/Water'),
                meta: { title: '水费账单', keepAlive: false, permission: [ 'water_fees' ] }
              },
              {
                path: '/property_bill/index/:id?',
                name: 'PropertyBill',
                component: () => import('@/views/a-property_bill/PropertyBill'),
                meta: { title: '物业账单', keepAlive: false, permission: [ 'property_fees' ] }
              },
              {
                path: '/rent_bill/index/:id?',
                name: 'RentBill',
                component: () => import('@/views/a-rent_bill/RentBill'),
                meta: { title: '租金账单', keepAlive: false, permission: [ 'rent_fees' ] }
              },
              {
                path: '/rent_bill/:id/reduce',
                name: 'RentBillReduce',
                hidden: true,
                component: () => import('@/views/a-rent_bill/table/RentBillReduceList'),
                meta: { title: '租金减免', keepAlive: false, permission: [ 'rent_fees' ] }
              },
              {
                path: '/rent_bill/reduce',
                name: 'RentBillReduceList',
                component: () => import('@/views/a-rent_bill/table/RentBillReduceAllList'),
                meta: { title: '租金减免', keepAlive: false, permission: [ 'rent_fees' ] }
              },
              {
                path: '/guarantee/index/:id?',
                name: 'Guarantee',
                component: () => import('@/views/a-guarantee/Guarantee'),
                meta: { title: '保证金账单', keepAlive: false, permission: [ 'guarantee_fees' ] }
              },
              {
                path: '/bill_statistics/index',
                name: 'BillStatistics',
                component: () => import('@/views/a-bill_statistics/BillStatistics'),
                meta: { title: '账单统计', keepAlive: false, permission: [ 'property_fees', 'rent_fees' ] }
              },
              {
                path: '/bill_percent/index',
                name: 'BillPercent',
                component: () => import('@/views/a-bill_payment_statistics/BillPaymentStatistics'),
                meta: { title: '缴费比例', keepAlive: false, permission: [ 'property_fees', 'rent_fees' ] }
              },
              {
                path: '/public_area_fee/index',
                name: 'PublicAreaFee',
                component: () => import('@/views/a-public_area_fee/PublicAreaFeeRecord'),
                meta: { title: '公共区域费用', keepAlive: false, permission: ['public_area_fees'] }
              },
              {
                path: '/urban_land_use_tax/index',
                name: 'UrbanLanUseTax',
                component: () => import('@/views/a-urban_land_use_tax/UrbanLandUseTaxRecord'),
                meta: { title: '城镇土地使用税', keepAlive: false, permission: ['urban_land_use_tax'] }
              },
              {
                path: '/pre_stored_electricity_fees/index',
                name: 'PreStoredElectricityFeesToIntegral',
                component: () => import('@/views/a-pre_stored_electricity/PreElectricityToIntegral'),
                meta: { title: '电费积分金额', keepAlive: false, permission: ['electricity_fees'] }
              }
            ]
          },
          {
            path: '/car',
            name: 'car',
            redirect: '/car/CarInformation',
            component: RouteView,
            meta: { title: '进出区管理', keepAlive: true, permission: [ 'administration', 'inout_application', 'inout_book', 'gateway', 'muck', 'temporary' ] },
            children: [
              {
                path: '/car/CarInformation',
                name: 'CarInformation',
                component: () => import('@/views/a-car/CarInformation'),
                meta: { title: '授权行政车辆', keepAlive: false, permission: [ 'administration' ] }
              },
              {
                path: '/car/CarInformation/:number/records',
                name: 'CarInformationRecords',
                hidden: true,
                component: () => import('@/views/a-car/table/Read'),
                meta: { title: '车辆记录', keepAlive: false, permission: [ 'administration' ] }
              },
              {
                path: '/car/Quick',
                name: 'InOut',
                component: () => import('@/views/a-inout/InOut'),
                meta: { title: '便捷出入区申请单', keepAlive: false, permission: [ 'inout_application' ] }
              },
              {
                path: '/car/Quick/:id/detail',
                name: 'InOutDetail',
                hidden: true,
                component: () => import('@/views/a-inout/table/Look'),
                meta: { title: '便捷出入区申请单', keepAlive: false, permission: [ 'inout_application' ] }
              },
              {
                path: '/car/book',
                name: 'Book',
                component: () => import('@/views/a-inout/Book'),
                meta: { title: '便捷出入区台账', keepAlive: false, permission: [ 'inout_book' ] }
              },
              {
                path: '/car/GatewayApplicationInformation',
                name: 'GatewayApplicationInformation',
                component: () => import('@/views/a-car/GatewayApplicationInformation'),
                meta: { title: '授权货车申请', keepAlive: false, permission: [ 'gateway' ] }
              },
              {
                path: '/muck/muck',
                name: 'muck',
                component: () => import('@/views/a-muck_record/MuckRecord'),
                meta: { title: '渣土记录', keepAlive: true, permission: [ 'muck' ] }
              },
              {
                path: '/car/temporary',
                name: 'Temporary',
                component: () => import('@/views/a-inout/Temporary'),
                meta: { title: '入区登记', keepAlive: false, permission: [ 'temporary' ] }
              }
            ]
          }
          ]
      },
      // a-user
      // {
      //   path: '/userList',
      //   name: 'user',
      //   redirect: '/user/UserInformation',
      //   component: PageView,
      //   meta: { title: '用户管理', keepAlive: true, icon: 'user', permission: [ 'user_information' ] },
      //   children: [
      //     {
      //       path: '/user/UserInformation',
      //       name: 'UserInformation',
      //       component: () => import('@/views/a-user/UserInformation'),
      //       meta: { title: '用户列表', keepAlive: false, permission: [ 'user_information' ] }
      //     },
      //     {
      //       path: '/role/RoleInformation',
      //       name: 'RoleList',
      //       component: () => import('@/views/a-role/RoleInformation'),
      //       meta: { title: '权限组配置', keepAlive: false, permission: [ 'role' ] }
      //     }/*,
      //     {
      //       path: 'notification',
      //       name: 'notification',
      //       component: () => import('@/views/a-notification/notification'),
      //       meta: { title: '通知', keepAlive: false, permission: [ 'notification' ] }
      //     } */
      //   ]
      // },
      // a-user
     /* {
        path: '/a-user_corporation',
        name: 'a-user_corporation',
        redirect: '/a-user_corporation/UserCorporationInformation',
        component: PageView,
        meta: { title: '用户企业', keepAlive: true, icon: bxAnaalyse, permission: [ 'a-user_corporation' ] },
        children: [
          {
            path: '/a-user_corporation/UserCorporationInformation',
            name: 'UserCorporationInformation',
            component: () => import('@/views/a-user_corporation/UserCorporationInformation'),
            meta: { title: '用户企业', keepAlive: false, permission: [ 'a-user_corporation' ] }
          }
        ]
      }, */
      // car
      // {
      //   path: '/car',
      //   name: 'car',
      //   redirect: '/car/CarInformation',
      //   component: PageView,
      //   meta: { title: '进出区管理', keepAlive: true, icon: 'car', permission: [ 'administration', 'inout_application', 'inout_book', 'gateway', 'muck', 'temporary' ] },
      //   children: [
      //     {
      //       path: '/car/CarInformation',
      //       name: 'CarInformation',
      //       component: () => import('@/views/a-car/CarInformation'),
      //       meta: { title: '授权行政车辆', keepAlive: false, permission: [ 'administration' ] }
      //     },
      //     {
      //       path: '/car/Quick',
      //       name: 'InOut',
      //       component: () => import('@/views/a-inout/InOut'),
      //       meta: { title: '便捷出入区申请单', keepAlive: false, permission: [ 'inout_application' ] }
      //     },
      //     {
      //       path: '/car/book',
      //       name: 'Book',
      //       component: () => import('@/views/a-inout/Book'),
      //       meta: { title: '便捷出入区台账', keepAlive: false, permission: [ 'inout_book' ] }
      //     },
      //     {
      //       path: '/car/GatewayApplicationInformation',
      //       name: 'GatewayApplicationInformation',
      //       component: () => import('@/views/a-car/GatewayApplicationInformation'),
      //       meta: { title: '授权货车申请', keepAlive: false, permission: [ 'gateway' ] }
      //     },
      //     {
      //       path: '/muck/muck',
      //       name: 'muck',
      //       component: () => import('@/views/a-muck_record/MuckRecord'),
      //       meta: { title: '渣土记录', keepAlive: true, permission: [ 'muck' ] }
      //     },
      //     {
      //       path: '/car/temporary',
      //       name: 'Temporary',
      //       component: () => import('@/views/a-inout/Temporary'),
      //       meta: { title: '入区登记', keepAlive: false, permission: [ 'temporary' ] }
      //     }
      //   ]
      // },
      // {
      //   path: '/bill',
      //   name: 'bill',
      //   redirect: '/water/index',
      //   component: PageView,
      //   meta: { title: '账单', keepAlive: true, icon: 'account-book', permission: [ 'water_fees', 'electricity_fees', 'property_fees', 'rent_fees' ] },
      //   children: [
      //     {
      //       path: '/electricity/prepaybill',
      //       name: 'ElectricityPrepaybill',
      //       component: () => import('@/views/a-electricity_prepaybill/PrepayBill'),
      //       meta: { title: '预付电费账单', keepAlive: false, permission: [ 'electricity_fees' ] }
      //     },
      //     {
      //       path: '/electricity/payment',
      //       name: 'Electricity',
      //       component: () => import('@/views/a-electricity/Electricity'),
      //       meta: { title: '电费账单', keepAlive: false, permission: [ 'electricity_fees' ] }
      //     },
      //     {
      //       path: '/water/index',
      //       name: 'Water',
      //       component: () => import('@/views/a-water/Water'),
      //       meta: { title: '水费账单', keepAlive: false, permission: [ 'water_fees' ] }
      //     },
      //     {
      //       path: '/property_bill/index',
      //       name: 'PropertyBill',
      //       component: () => import('@/views/a-property_bill/PropertyBill'),
      //       meta: { title: '物业账单', keepAlive: false, permission: [ 'property_fees' ] }
      //     },
      //     {
      //       path: '/rent_bill/index',
      //       name: 'RentBill',
      //       component: () => import('@/views/a-rent_bill/RentBill'),
      //       meta: { title: '租金账单', keepAlive: false, permission: [ 'rent_fees' ] }
      //     },
      //     {
      //       path: '/bill_statistics/index',
      //       name: 'BillStatistics',
      //       component: () => import('@/views/a-bill_statistics/BillStatistics'),
      //       meta: { title: '账单统计', keepAlive: false, permission: [ 'property_fees', 'rent_fees' ] }
      //     },
      //     {
      //       path: '/bill_percent/index',
      //       name: 'BillPercent',
      //       component: () => import('@/views/a-bill_payment_statistics/BillPaymentStatistics'),
      //       meta: { title: '缴费比例', keepAlive: false, permission: [ 'property_fees', 'rent_fees' ] }
      //     }
      //   ]
      // },
      // {
      //   path: '/electricity_meter',
      //   name: 'electricity_meter',
      //   redirect: '/electricity_meter/index',
      //   component: PageView,
      //   meta: { title: '电表', keepAlive: true, icon: 'thunderbolt', permission: ['electricity_meter'] },
      //   children: [
      //     {
      //       path: '/electricity_meter/group',
      //       name: 'ElectricityMeterGroup',
      //       component: () => import('@/views/a-electricity-meter_group/Group'),
      //       meta: { title: '电表组', keepAlive: false }
      //     },
      //     {
      //       path: '/electricity_meter/group/record',
      //       name: 'ElectricityMeterGroupRecord',
      //       component: () => import('@/views/a-electricity-meter_group_record/GroupRecord'),
      //       meta: { title: '电表组记录', keepAlive: false }
      //     },
      //     {
      //       path: '/electricity_meter',
      //       name: 'ElectricityMeter',
      //       component: () => import('@/views/a-electricity-meter/ElectricityMeter'),
      //       meta: { title: '电表列表', keepAlive: false }
      //     },
      //     {
      //       path: '/electricity_meter_record',
      //       name: 'ElectricityMeterRecord',
      //       component: () => import('@/views/a-electricity-meter_record/ElectricityRecord'),
      //       meta: { title: '抄表记录', keepAlive: false }
      //     }
      //   ]
      // },
      // {
      //   path: '/water_meter',
      //   name: 'water_meter',
      //   redirect: '/water_meter/index',
      //   component: PageView,
      //   meta: { title: '水表', keepAlive: true, icon: 'ellipsis', permission: [ 'water_meter', 'water_meter_record' ] },
      //   children: [
      //     {
      //       path: '/water_meter/index',
      //       name: 'WaterMeter',
      //       component: () => import('@/views/a-water_meter/WaterMeter'),
      //       meta: { title: '水表管理', keepAlive: false, permission: [ 'water_meter' ] }
      //     },
      //     {
      //       path: '/water_meter/record',
      //       name: 'WaterMeterRecord',
      //       component: () => import('@/views/a-water_meter/WaterMeterRecord'),
      //       meta: { title: '抄表记录', keepAlive: false, permission: [ 'water_meter_record' ] }
      //     }
      //   ]
      // },
      // a-contract
      // {
      //   path: '/contract',
      //   name: 'contract',
      //   redirect: '/contract/ContractInformation',
      //   component: PageView,
      //   meta: { title: '合同', keepAlive: true, icon: 'file', permission: [ 'contract_information', 'contract_template' ] },
      //   children: [
      //     {
      //       path: '/rent_contract/index',
      //       name: 'RentContract',
      //       component: () => import('@/views/a-rent_contract/RentContract'),
      //       meta: { title: '合同信息', keepAlive: false, permission: [ 'contract_information' ] }
      //     },
      //     {
      //       path: '/rent_contract/:id/zone_property_cell/',
      //       name: 'RentContractProperties',
      //       hidden: true,
      //       component: () => import('@/views/a-rent_contract/table/PropertiesList'),
      //       meta: { title: '合同资产详情', keepAlive: false, permission: [ 'contract_information' ] }
      //     },
      //     {
      //       path: '/rent_contract/create',
      //       name: 'RentContractCreate',
      //       hidden: true,
      //       component: () => import('@/views/a-rent_contract/modules/CreateForm'),
      //       meta: { title: '新建合同', keepAlive: false, permission: [ 'contract_information' ] }
      //     },
      //     {
      //       path: '/contract/ContractInformation',
      //       name: 'ContractInformation',
      //       component: () => import('@/views/a-contract/ContractInformation'),
      //       meta: { title: '合同模板', keepAlive: false, permission: [ 'contract_template' ] }
      //     },
      //     {
      //       path: '/rent_contract/attachment/:id',
      //       name: 'attachment',
      //       hidden: true,
      //       // component: () => import('@/views/a-rent_contract/RentContractAttachment'),
      //       component: () => import('@/views/a-rent_contract/modules/CreateFuJianForm'),
      //       meta: { title: '附件', keepAlive: false }
      //     },
      //     {
      //       path: '/contract/ContractBankAccountInformation',
      //       name: 'ContractBankAccountInformation',
      //       hidden: true,
      //       component: () => import('@/views/a-bank-account/BankAccountInformation'),
      //       meta: { title: '打款账户', keepAlive: false, permission: [ 'bank_payment_account' ] }
      //     }
      //   ]
      // },
      {
        path: '/payment_ticket',
        name: 'payment_ticket',
        redirect: '/payment_ticket/index',
        component: PageView,
        meta: { title: '财务', keepAlive: true, icon: 'bank', permission: ['property_bank_flow', 'fund_flow', 'property_balance'] },
        children: [
          // {
          //   path: '/payment_ticket/index',
          //   name: 'PaymentTicket',
          //   component: () => import('@/views/a-payment_ticket/PaymentTicket'),
          //   meta: { title: '付款凭证', keepAlive: false, permission: [ 'payment_ticket' ] }
          // },
          // {
          //   path: '/fapiao_application/index',
          //   name: 'FapiaoApplication',
          //   component: () => import('@/views/a-fapiao_application/FapiaoApplication'),
          //   meta: { title: '发票申请', keepAlive: false, permission: [ 'fapiao_application' ] }
          // },
          {
            path: '/property/bank_flow',
            name: 'PropertyBankFlow',
            component: () => import('@/views/a-property_bank_flow/BankFlow'),
            meta: { title: '银企直连对账单', keepAlive: false, permission: [ 'property_bank_flow' ] }
          },
          {
            path: '/fund/flow',
            name: 'FundFlow',
            component: () => import('@/views/a-fund_flow/FundFlow'),
            meta: { title: '资金流水', keepAlive: false, permission: [ 'fund_flow' ] }
          },
          // {
          //   path: '/bank_water/index',
          //   name: 'BankWater',
          //   component: () => import('@/views/a-bank-water/BankWater'),
          //   meta: { title: '银行流水', keepAlive: false, permission: [ 'payment_bank_water' ] }
          // },
          {
            path: '/property_balance/index',
            name: 'PropertyBalance',
            component: () => import('@/views/a-property_balance/PropertyBalance'),
            meta: { title: '企业资产余额', keepAlive: false, permission: [ 'property_balance' ] }
          },
          {
            path: '/property_balance/:id/detail',
            name: 'PropertyBalanceDetail',
            hidden: true,
            component: () => import('@/views/a-property_balance/table/Detail'),
            meta: { title: '企业资产余额', keepAlive: false }
          },
          {
            path: '/prepay/bill/electricity',
            name: 'PrepayBillElectricity',
            component: () => import('@/views/a-electricity_prepaybill/table/PrepayBillList'),
            meta: { title: '电费预付费账单', keepAlive: false }
          }
        ]
      },
      // {
      //   path: '/dingding',
      //   name: 'dingding',
      //   redirect: '/dingding/dept',
      //   component: PageView,
      //   meta: { title: '钉钉', keepAlive: true, icon: 'dingding', permission: [ 'dingding' ] },
      //   children: [
      //     {
      //       path: '/dingding/dept',
      //       name: 'DingdingDept',
      //       component: () => import('@/views/a-dingding_dept/DingDingDept'),
      //       meta: { title: '钉钉部门', keepAlive: false }
      //     },
      //     {
      //       path: '/dingding/user',
      //       name: 'DingdingUser',
      //       component: () => import('@/views/a-dingding_user/DingDingUser'),
      //       meta: { title: '钉钉用户', keepAlive: false }
      //     }
      //   ]
      // },
      // {
      //   path: '/zone_property',
      //   name: 'zone_property',
      //   redirect: '/zone_property/index',
      //   component: PageView,
      //   meta: { title: '资产', keepAlive: true, icon: 'property-safety', permission: [ 'zone_property' ] },
      //   children: [
      //     {
      //       path: '/zone_property/index',
      //       name: 'ZoneProperty',
      //       component: () => import('@/views/a-property/Property'),
      //       meta: { title: '资产信息', keepAlive: false, permission: [ 'zone_property' ] }
      //     },
      //     {
      //       path: '/zone_property_cell/index/:id',
      //       name: 'ZonePropertyCell',
      //       hidden: true,
      //       component: () => import('@/views/a-property/cell/PropertyCell'),
      //       meta: { title: '资产明细', keepAlive: false, permission: [ 'zone_property' ] }
      //     },
      //     {
      //       path: '/cavancy_rate/index/',
      //       name: 'CavancyRate',
      //       component: () => import('@/views/a-property/cavancy_rate/CavancyRate'),
      //       meta: { title: '资产统计', keepAlive: false, permission: [ 'zone_property' ] }
      //     }
      //   ]
      // },
      // 订单
      {
        path: '/e/customs_order',
        component: PageView,
        redirect: '/e/customs_order/order',
        meta: { title: '1210报关', keepAlive: true, icon: 'unordered-list', permission: [ 'customs_entry_1210', 'customs_config_1210' ] },
        name: 'CustomsOrder',
        children: [
          {
            path: 'order',
            name: 'CustomsOrderList',
            component: () => import('@/views/a-customs_order/CustomsOrder'),
            meta: { title: '申请订单列表', keepAlive: true, permission: [ 'customs_entry_1210' ] }
          },
          {
            path: 'customs_logistics',
            name: 'CustomsLogisticsList',
            component: () => import('@/views/a-customs_order/CustomsLogisticsList'),
            meta: { title: '申报运单列表', keepAlive: true, permission: [ 'customs_entry_1210' ] }
          },
          {
            path: 'customs_payment_check',
            name: 'CustomsPaymentCheckList',
            component: () => import('@/views/a-customs_order/CustomsPaymentCheck'),
            meta: { title: '179支付单检查列表', keepAlive: true, permission: [ 'customs_entry_1210' ] }
          },
          {
            path: 'customs_inventory',
            name: 'CustomsInventory',
            component: () => import('@/views/a-customs_order/CustomsInventory'),
            meta: { title: '进口清单列表', keepAlive: true, permission: [ 'customs_entry_1210' ] }
          },
          {
            path: 'corporation_config',
            name: 'corporationConfig',
            component: () => import('@/views/a-customs_order/CorporationConfig'),
            meta: { title: '1210配置', keepAlive: true, permission: [ 'customs_config_1210' ] }
          },
          {
            path: ':order_id/goods',
            name: 'CustomsOrderGoodsList',
            hidden: true,
            component: () => import('@/views/a-customs_order/table/CustomsOrderGoodsList'),
            meta: { title: '订单商品列表', keepAlive: true }
          },
          {
            path: ':order_id/list',
            name: 'CustomsInventoryList',
            hidden: true,
            component: () => import('@/views/a-customs_order/table/CustomsInventoryList'),
            meta: { title: '订单商品列表', keepAlive: true }
          }
        ]
      },
      {
        path: '/zb/wms',
        component: PageView,
        redirect: '/zb/wms/warehouse',
        name: 'wms',
        meta: { title: '仓库/门店', icon: 'stock', permission: [ 'warehouse', 'cargo_space', 'warehouse_goods', 'goods_declaration', 'goods_purchase', 'goods_purchase_declaration', 'goods_inventory', 'transfer_inventory', 'goods_loss', 'wms_inventory', 'wms_order', 'wms_picks', 'shop_statistics' ] },
        children: [
          {
            path: 'warehouse',
            name: 'wms_warehouse_list',
            component: () => import('@/views/c-wms/warehouse/WareHouse'),
            meta: { title: '仓库', permission: [ 'warehouse', 'cargo_space' ] }
          },
          {
            path: 'warehouse_edit/:id',
            name: 'wms_warehouse_list_edit',
            hidden: true,
            component: () => import('@/views/c-wms/warehouse/table/Edit'),
            meta: { title: '仓库编辑' }
          },
          {
            path: 'goods_category',
            name: 'goods_category',
            component: () => import('@/views/c-wms/goods_category/Category'),
            meta: { title: '货品分类', permission: [ 'warehouse_goods' ] }
          },
          {
            path: 'goods_category/:id',
            name: 'goods_category_edit',
            hidden: true,
            component: () => import('@/views/c-wms/goods_category/table/Edit'),
            meta: { title: '货品分类' }
          },
          {
            path: 'goods',
            name: 'wms_goods_list',
            component: () => import('@/views/c-wms/goods/Goods'),
            meta: { title: '货品', permission: ['warehouse_goods'] }
          },
          {
            path: 'goods_union/:id?',
            name: 'wms_goods_union',
            hidden: true,
            component: () => import('@/views/c-wms/goods/table/UnionWithGoodsList'),
            meta: { title: '套盒内货品' }
          },
          {
            path: 'goods_declaration/:barcode?',
            name: 'goods_declaration_list',
            component: () => import('@/views/c-wms/goods_declaration/GoodsDeclaration'),
            meta: { title: '商品申报信息', permission: ['goods_declaration'] }
          },
          {
            path: 'consumption_tax_level/:barcode?',
            name: 'consumption_tax_level_list',
            hidden: true,
            component: () => import('@/views/c-wms/consumption_tax_level/ConsumptionTaxLevel'),
            meta: { title: '消费税' }
          },
          {
            path: 'goods_purchase/:commodity_id?',
            name: 'goods_purchase_list',
            component: () => import('@/views/c-wms/goods_purchase/GoodsPurchase'),
            meta: { title: '货品采购', permission: ['goods_purchase'] }
          },
          {
            path: 'goods_purchase_custom_goods',
            name: 'GoodsPurchaseCustomGoods',
            hidden: true,
            component: () => import('@/views/c-wms/goods_purchase/modules/CreateCustomGoods'),
            meta: { title: '新建套盒采购' }
          },
          {
            path: 'goods_purchase_declaration/',
            name: 'goods_purchase_declaration_list',
            component: () => import('@/views/c-wms/goods_purchase_declaration/GoodsPurchaseDeclaration'),
            meta: { title: '商品采购申报', permission: ['goods_purchase_declaration'] }
          },
          {
            path: 'warehouse/inventory',
            name: 'wms_warehouse_detail_count',
            component: () => import('@/views/c-wms/inventory/InventoryList'),
            meta: { title: '库存', permission: ['goods_inventory'] }
          },
          {
            path: 'wechat_order',
            name: 'wms_wechat_order_list',
            component: () => import('@/views/c-wms/wechat_order/WechatOrder'),
            meta: { title: '微信订单', permission: ['wms_order'] }
          },
          {
            path: 'wechat_order_detail',
            name: 'wms_wechat_order_detail',
            hidden: true,
            component: () => import('@/views/c-wms/wechat_order/table/GoodsList'),
            meta: { title: '微信订单关联商品列表' }
          },
          {
            path: 'counter_order',
            name: 'shop_counter_order_list',
            component: () => import('@/views/c-wms/counter_order/CounterOrder'),
            meta: { title: '柜台订单', permission: ['wms_order'] }
          },
          {
            path: 'counter_order_detail',
            name: 'shop_counter_order_detail',
            hidden: true,
            component: () => import('@/views/c-wms/counter_order/table/GoodsList'),
            meta: { title: '柜台订单关联商品列表' }
          },
          {
            path: 'transfer_inventory',
            name: 'wms_transfer_inventory_list',
            component: () => import('@/views/c-wms/trans_inventory/TransInventory'),
            meta: { title: '调拨单', permission: ['transfer_inventory'] }
          },
          {
            path: 'transfer_inventory_detail',
            name: 'wms_transfer_inventory_detail_list',
            hidden: true,
            component: () => import('@/views/c-wms/trans_inventory_detail/TransInventory'),
            meta: { title: '调拨单详情' }
          },
          {
            path: 'goods_loss',
            name: 'wms_goods_loss_list',
            component: () => import('@/views/c-wms/goods_loss/GoodsLoss'),
            meta: { title: '商品灭失', permission: ['goods_loss'] }
          },
          {
            path: 'wms_inventory',
            name: 'wmsInventory',
            component: () => import('@/views/c-wms/take_inventory/TakeInventory'),
            meta: { title: '盘存', permission: ['wms_inventory'] }
          },
          {
            path: 'outflow_list',
            name: 'wms_common_warehouse_outflow_list',
            hidden: true,
            component: () => import('@/views/c-wms/outflow/Outflow'),
            meta: { title: '出库' }
          },
          {
            path: 'warehouse/:id',
            name: 'wms_warehouse_detail',
            hidden: true,
            component: () => import('@/views/c-wms/cargo_space/CargoSpace'),
            meta: { title: '公共库位' }
          },
          {
            path: 'consumable',
            name: 'wms_consumable_list',
            hidden: true,
            component: () => import('@/views/c-wms/consumable/Consumable'),
            meta: { title: '耗材' }
          },
          {
            path: 'commodity',
            name: 'wms_commodity_list',
            hidden: true,
            component: () => import('@/views/c-wms/commodity/Commodity'),
            meta: { title: '商品' }
          },
          {
            path: 'commodity/:id',
            name: 'wms_commodity_by_goods_list',
            hidden: true,
            component: () => import('@/views/c-wms/commodity/table/CommodityByGoodsIdList'),
            meta: { title: '商品' }
          },
          {
            path: 'picks',
            name: 'picks_list',
            component: () => import('@/views/c-wms/pick/Picks'),
            meta: { title: '拣货单', permission: ['wms_picks'] }
          },
          {
            path: 'picks/:id/goods/',
            name: 'wms_goods_by_picks_list',
            hidden: true,
            component: () => import('@/views/c-wms/pick/table/CommodityByPicksList'),
            meta: { title: '拣货单货品列表' }
          },
          {
            path: '/statistics/goods_order',
            name: 'statistics_goods_order',
            component: () => import('@/views/a-shop_statistics/GoodsOrderStatisticsList'),
            meta: { title: '商品销售统计', permission: ['shop_statistics'] }
          },
          {
            path: 'wms_inventory_detail/:take_inventory_id',
            name: 'wmsInventoryDetail',
            hidden: true,
            component: () => import('@/views/c-wms/take_inventory/table/Read'),
            meta: { title: '盘存详情' }
          },
          {
            path: 'intercept_order',
            name: 'wms_intercept_order',
            hidden: true,
            component: () => import('@/views/c-wms/intercept_order/InterceptOrder'),
            meta: { title: '拦截单' }
          },
          {
            path: 'config',
            hidden: true,
            component: () => import('@/views/c-wms/config/Config.vue'),
            name: 'commonStoreConfig',
            meta: {
              title: '配置'
            }
          }
        ]
      },
      {
        path: '/applet/wechat',
        component: PageView,
        redirect: '/applet/applet',
        name: 'applet_wechat',
        meta: { title: '零售端', icon: 'plus', permission: ['shop_coupon', 'shop_discount', 'shop_value_card', 'shop_goods_category', 'shop_goods', 'shop_goods_group', 'shop_user', 'shop_banner', 'shop_notify', 'shop_delivery_address', 'shop_wechat_order', 'shop_pay_method', 'shop_counter_order'] },
        children: [
          {
            path: '/commonshop',
            name: 'commonshopManage',
            redirect: '/commonshop/coupon',
            component: RouteView,
            meta: { title: '通用管理', permission: ['shop_coupon', 'shop_discount', 'shop_value_card'] },
            children: [
              {
                path: 'coupon',
                component: () => import('@/views/a-applet/shop_manage/coupon/CouponList'),
                name: 'Coupon',
                meta: {
                  title: '优惠劵', permission: ['shop_coupon']
                }
              },
              {
                path: 'coupon_create',
                component: () => import('@/views/a-applet/shop_manage/coupon/module/CreateCouponForm'),
                name: 'CouponCreate',
                hidden: true,
                meta: {
                  title: '创建优惠劵'
                }
              },
              {
                path: 'coupon_category',
                component: () => import('@/views/a-applet/shop_manage/coupon/CouponRelatedCategoryList'),
                name: 'CouponCategory',
                hidden: true,
                meta: {
                  title: '优惠劵关联分类'
                }
              },
              {
                path: 'coupon_goods',
                component: () => import('@/views/a-applet/shop_manage/coupon/CouponRelatedGoodsList'),
                name: 'CouponGoods',
                hidden: true,
                meta: {
                  title: '优惠劵关联商品'
                }
              },
              {
                path: 'coupon_user',
                component: () => import('@/views/a-applet/shop_manage/coupon/CouponRelatedUserList'),
                name: 'CouponUser',
                hidden: true,
                meta: {
                  title: '优惠劵领取用户'
                }
              },
              {
                path: 'discount',
                component: () => import('@/views/a-applet/shop_manage/discount/DiscountList'),
                name: 'Discount',
                meta: {
                  title: '商品折扣', permission: ['shop_discount']
                }
              },
              {
                path: 'group_discount',
                component: () => import('@/views/a-applet/shop_manage/group_discount/GroupDiscountList'),
                name: 'GroupDiscount',
                meta: {
                  title: '组合优惠', permission: ['shop_discount']
                }
              },
              {
                path: 'group_discount_goods',
                component: () => import('@/views/a-applet/shop_manage/group_discount/GroupDiscountGoodsList'),
                name: 'GroupDiscountGoods',
                hidden: true,
                meta: {
                  title: '组合优惠关联商品'
                }
              },
              {
                path: 'value_card',
                component: () => import('@/views/a-applet/shop_manage/value_card/ValueCardRecordList'),
                name: 'ValueCardRecordList',
                meta: {
                  title: '储值卡', permission: ['shop_value_card']
                }
              },
              {
                path: 'value_card_list',
                component: () => import('@/views/a-applet/shop_manage/value_card/ValueCardList'),
                name: 'ValueCardList',
                hidden: true,
                meta: {
                  title: '储值卡'
                }
              },
              {
                path: 'refund/goods',
                component: () => import('@/views/a-applet/shop_manage/RefundGoodsList'),
                name: 'RefundGoodsList',
                hidden: true,
                meta: {
                  title: '退货商品'
                }
              },
              {
                path: 'refund/address',
                component: () => import('@/views/a-applet/shop_manage/RefundAddressList'),
                name: 'RefundAddressList',
                hidden: true,
                meta: {
                  title: '退货地址'
                }
              },
              {
                path: 'order_statistics',
                component: () => import('@/views/a-applet/shop_manage/OrderStatisticsList'),
                name: 'OrderStatisticsAppletList',
                hidden: true,
                meta: {
                  title: '商品销售统计'
                }
              },
              {
                path: 'config',
                component: () => import('@/views/a-applet/shop_manage/MallAppletConfig.vue'),
                name: 'mallAppletConfig',
                hidden: true,
                meta: {
                  title: '配置'
                }
              }
            ]
          },
          // 商品管理
          {
            path: '/applet_goods',
            name: 'goodsAppletManage',
            redirect: '/applet_goods/goods_list',
            component: RouteView,
            meta: {
              title: '商品管理', permission: ['shop_goods_category', 'shop_goods', 'shop_goods_group']
            },
            children: [
              {
                path: 'category',
                name: 'applet_goods_category',
                component: () => import('@/views/a-applet/goods_category/Category'),
                meta: { title: '商品分类', permission: ['shop_goods_category'] }
              },
              {
                path: 'goods_list',
                component: () => import('@/views/a-applet/shop_goods/list'),
                name: 'goodsList',
                meta: {
                  title: '商品列表', permission: ['shop_goods']
                }
              },
              {
                path: 'vip_price',
                hidden: true,
                component: () => import('@/views/a-applet/shop_goods/goods_price/PriceList'),
                name: 'GoodsPrice',
                meta: {
                  title: '商品会员价'
                }
              },
              {
                path: 'gallery',
                hidden: true,
                component: () => import('@/views/a-applet/shop_goods/goods_gallery/GalleryList'),
                name: 'GoodsGallery',
                meta: {
                  title: '商品详情页滚动图'
                }
              },
              {
                path: 'group',
                component: () => import('@/views/a-applet/wx_goods_group/GoodsGroupManage'),
                name: 'GoodsGroup',
                meta: {
                  title: '商品组', permission: ['shop_goods_group']
                }
              },
              {
                path: 'group/:id/goods',
                hidden: true,
                component: () => import('@/views/a-applet/wx_goods_group/GroupGoodsList'),
                name: 'GoodsGroupList',
                meta: {
                  title: '组内商品'
                }
              },
              {
                path: 'create',
                component: () => import('@/views/a-applet/shop_goods/create'),
                name: 'goodsCreate',
                hidden: true,
                meta: {
                  title: '商品上架'
                }
              },
              {
                path: 'edit',
                component: () => import('@/views/a-applet/shop_goods/edit'),
                name: 'goodsEdit',
                meta: {
                  title: '商品编辑'
                },
                hidden: true
              },
              {
                path: 'mall_consumbales',
                component: () => import('@/views/a-applet/wx_mall_cunsumbales/MallConsumbales'),
                name: 'MallConsumbales',
                hidden: true,
                meta: {
                  title: '商品耗材'
                }
              },
              {
                path: 'mall_goods_consumbales',
                component: () => import('@/views/a-applet/wx_mall_goods_cunsumbales/MallGoodsConsumbales'),
                name: 'MallGoodsConsumbales',
                hidden: true,
                meta: {
                  title: '商品耗材关联'
                }
              }
            ]
          },
          {
            path: '/appletshop',
            name: 'appletshopManage',
            redirect: '/appletshop/shop_user',
            component: RouteView,
            meta: { title: '小程序管理', permission: ['shop_user', 'shop_banner', 'shop_notify', 'shop_delivery_address', 'shop_wechat_order'] },
            children: [
              {
                path: 'shop_user',
                component: () => import('@/views/a-applet/wx_shop_user/UserInformation'),
                name: 'shopUserList',
                meta: {
                  title: '商城用户', permission: ['shop_user']
                }
              },
              {
                path: 'banner',
                component: () => import('@/views/a-applet/wx_banner/BannerManage'),
                name: 'bannerList',
                meta: {
                  title: '首页滚动屏', permission: ['shop_banner']
                }
              },
              {
                path: 'notify/manage',
                component: () => import('@/views/a-applet/shop_manage/NotifyList'),
                name: 'NotifyList',
                meta: {
                  title: '公告管理', permission: ['shop_notify']
                }
              },
              {
                path: 'delivery/address',
                component: () => import('@/views/a-applet/shop_manage/deliveryaddress/DeliveryAddressList'),
                name: 'DeliveryAddress',
                meta: {
                  title: '自提点', permission: ['shop_delivery_address']
                }
              },
              {
                path: 'order',
                component: () => import('@/views/a-applet/shop_manage/OrderList'),
                name: 'OrderAppletList',
                meta: {
                  title: '订单管理', permission: ['shop_wechat_order']
                }
              },
              {
                path: 'refund',
                component: () => import('@/views/a-applet/shop_manage/refund/RefundList'),
                name: 'AppletRefundList',
                meta: {
                  title: '订单退款',
                  permission: [ 'shop_wechat_order' ]
                }
              },
              {
                path: 'order/detail',
                component: () => import('@/views/a-applet/shop_manage/OrderDetail'),
                name: 'OrderAppletDetail',
                hidden: true,
                meta: {
                  title: '订单详情'
                }
              },
              {
                path: 'order/detail_refund',
                component: () => import('@/views/a-applet/shop_manage/OrderDetailRefund'),
                name: 'OrderAppletDetailRefund',
                hidden: true,
                meta: {
                  title: '退款记录'
                }
              },
              {
                path: 'refund/goods',
                component: () => import('@/views/a-applet/shop_manage/RefundGoodsList'),
                name: 'RefundGoodsList',
                hidden: true,
                meta: {
                  title: '退货商品'
                }
              },
              {
                path: 'refund/address',
                component: () => import('@/views/a-applet/shop_manage/RefundAddressList'),
                name: 'RefundAddressList',
                hidden: true,
                meta: {
                  title: '退货地址'
                }
              },
              {
                path: 'order_statistics',
                component: () => import('@/views/a-applet/shop_manage/OrderStatisticsList'),
                name: 'OrderStatisticsAppletList',
                hidden: true,
                meta: {
                  title: '商品销售统计'
                }
              },
              {
                path: 'config',
                component: () => import('@/views/a-applet/shop_manage/MallAppletConfig.vue'),
                name: 'mallAppletConfig',
                hidden: true,
                meta: {
                  title: '配置'
                }
              }
            ]
          },
          {
            path: '/countershop',
            name: 'countershopManage',
            redirect: '/countershop/counter_order',
            component: RouteView,
            meta: { title: '收银台管理', permission: ['shop_pay_method', 'shop_counter_order'] },
            children: [
              {
                path: 'counter_order_pay_method',
                name: 'shop_counter_order_method_list',
                component: () => import('@/views/c-wms/counter_order_pay_method/CounterOrderPayMethod'),
                meta: { title: '支付方式', permission: ['shop_pay_method'] }
              },
              {
                path: 'counter_order_list',
                component: () => import('@/views/a-counter/manage/OrderList'),
                name: 'CounterOrderList',
                meta: {
                  title: '订单管理', permission: ['shop_counter_order']
                }
              },
              {
                path: 'counter_refund',
                component: () => import('@/views/a-counter/manage/refund/RefundList'),
                name: 'CounterRefundList',
                meta: {
                  title: '退款记录', permission: ['shop_counter_order']
                }
              },
              {
                path: 'counter_order/detail',
                component: () => import('@/views/a-counter/manage/OrderDetail'),
                name: 'CounterOrderDetail',
                hidden: true,
                meta: {
                  title: '订单详情'
                }
              },
              {
                path: 'counter_order/detail_refund',
                component: () => import('@/views/a-counter/manage/refund/OrderDetailRefund'),
                name: 'OrderCounterDetailRefund',
                hidden: true,
                meta: {
                  title: '订单退款'
                }
              },
              {
                path: 'counter_order_refund/goods',
                component: () => import('@/views/a-counter/manage/refund/RefundGoodsList'),
                name: 'RefundGoodsList',
                hidden: true,
                meta: {
                  title: '退货商品'
                }
              },
              {
                path: 'counter_order_refund/address',
                component: () => import('@/views/a-applet/shop_manage/RefundAddressList'),
                name: 'RefundAddressList',
                hidden: true,
                meta: {
                  title: '退货地址'
                }
              },
              {
                path: 'order_statistics',
                component: () => import('@/views/a-applet/shop_manage/OrderStatisticsList'),
                name: 'OrderStatisticsAppletList',
                hidden: true,
                meta: {
                  title: '商品销售统计'
                }
              }
            ]
          },
          {
            path: '/statistics',
            name: 'statistics',
            redirect: '/statistics/goods_order',
            component: RouteView,
            meta: { title: '统计', permission: ['shop_statistics'] },
            children: [
              {
                path: '/statistics/order',
                name: 'statistics_order',
                component: () => import('@/views/a-shop_statistics/OrderStatisticsList'),
                meta: { title: '订单统计' }
              }
              ]
          }
        ]
      },
      {
        path: '/trade',
        component: PageView,
        redirect: '/trade/corporation',
        name: 'Trade',
        meta: { title: '贸易', icon: 'audit', keepAlive: true, permission: ['trade_base', 'trade_project', 'trade_approval'] },
        children: [
          {
            path: '/trade/base',
            name: 'TradeBase',
            redirect: '/trade/corporation',
            component: RouteView,
            meta: { title: '基础', keepAlive: true, permission: ['trade_base'] },
            children: [
              {
                path: '/trade/corporation',
                name: 'TradeCorporation',
                component: () => import('@/views/a-trade/corporation/Corporation'),
                meta: { title: '主体企业' }
              },
              {
                path: '/trade/corporation/:id',
                name: 'TradeCorporationEdit',
                hidden: true,
                component: () => import('@/views/a-trade/corporation/table/Edit'),
                meta: { title: '主体企业', keepAlive: false }
              },
              {
                path: '/trade/bank',
                name: 'TradeBank',
                component: () => import('@/views/a-trade/bank/Bank'),
                meta: { title: '授信银行' }
              },
              {
                path: '/trade/bank/:id',
                name: 'TradeBankEdit',
                hidden: true,
                component: () => import('@/views/a-trade/bank/table/Edit'),
                meta: { title: '授信银行', keepAlive: false }
              },
              {
                path: '/trade/corporation_credit',
                name: 'TradeCorporationCredit',
                component: () => import('@/views/a-trade/corporation_credit/CorporationCredit'),
                meta: { title: '主体企业信用' }
              },
              {
                path: '/trade/category',
                name: 'TradeCategory',
                component: () => import('@/views/a-trade/category/Category'),
                meta: { title: '交易品种' }
              },
              {
                path: '/trade/category/:id',
                name: 'TradeCategoryEdit',
                hidden: true,
                component: () => import('@/views/a-trade/category/table/Edit'),
                meta: { title: '交易品种', keepAlive: false }
              },
              {
                path: '/trade/client',
                name: 'TradeClient',
                component: () => import('@/views/a-trade/client/Client'),
                meta: { title: '客户' }
              },
              {
                path: '/trade/client/:id',
                name: 'TradeClientEdit',
                hidden: true,
                component: () => import('@/views/a-trade/client/table/Edit'),
                meta: { title: '客户', keepAlive: false }
              },
              {
                path: '/trade/client/:client_id/material',
                name: 'TradeClientMaterial',
                hidden: true,
                component: () => import('@/views/a-trade/client/material/Material'),
                meta: { title: '客户资料', keepAlive: false }
              },
              {
                path: '/trade/client/:client_id/material/:material_id',
                name: 'TradeClientMaterial',
                hidden: true,
                component: () => import('@/views/a-trade/client/material/table/Edit'),
                meta: { title: '客户资料', keepAlive: false }
              },
              {
                path: '/trade/client/:client_id/material/:material_id/attachment',
                name: 'TradeClientMaterialAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/client/material/attachment/Attachment'),
                meta: { title: '客户资料附件', keepAlive: false }
              },
              {
                path: '/trade/client/:client_id/material/:material_id/attachment/:attachment_id',
                name: 'TradeClientMaterialAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/client/material/attachment/table/Edit'),
                meta: { title: '客户资料附件', keepAlive: false }
              },
              {
                path: '/trade/warehouse',
                name: 'TradeTradeWarehouse',
                component: () => import('@/views/a-trade/warehouse/Warehouse'),
                meta: { title: '仓库' }
              },
              {
                path: '/trade/warehouse/:id',
                name: 'TradeTradeWarehouseEdit',
                hidden: true,
                component: () => import('@/views/a-trade/warehouse/table/Edit'),
                meta: { title: '仓库', keepAlive: false }
              }
              ]
          },
          {
            path: '/trade/project',
            name: 'TradeProject',
            component: () => import('@/views/a-trade/project/Project'),
            meta: { title: '项目', keepAlive: true, permission: ['trade_project'] }
          },
          {
            path: '/trade/project/:id',
            name: 'TradeProjectEdit',
            hidden: true,
            component: () => import('@/views/a-trade/project/table/Edit'),
            meta: { title: '项目' }
          },
          {
            path: '/trade/project/:project_id/detail',
            name: 'TradeProjectDetail',
            hidden: true,
            component: () => import('@/views/a-trade/project/detail/Detail'),
            meta: { title: '项目', keepAlive: true, permission: ['trade_project'] }
          },
          {
            path: '/trade/project/:project_id/client',
            name: 'TradeProjectClient',
            hidden: true,
            component: () => import('@/views/a-trade/project/client/Client'),
            meta: { title: '项目客户', keepAlive: false }
          },
          {
            path: '/trade/project/:project_id/remark',
            name: 'TradeProjectRemark',
            hidden: true,
            component: () => import('@/views/a-trade/project/remark/Remark'),
            meta: { title: '项目备注', keepAlive: false }
          },
          {
            path: '/trade/contract_file',
            name: 'TradeContract',
            redirect: '/trade/sell_contract',
            component: RouteView,
            meta: { title: '合同资料', keepAlive: true },
            children: [
              {
                path: '/trade/sell_contract',
                name: 'SellContract',
                component: () => import('@/views/a-trade/sell_contract/SellContract'),
                meta: { title: '购销合同' }
              },
              {
                path: '/trade/sell_contract/:id',
                name: 'SellContractEdit',
                hidden: true,
                component: () => import('@/views/a-trade/sell_contract/table/Edit'),
                meta: { title: '购销合同' }
              },
              {
                path: '/trade/sell_contract/:sell_contract_id/keypoint',
                name: 'SellContractKeyPoint',
                hidden: true,
                component: () => import('@/views/a-trade/sell_contract/keypoint/KeyPoint'),
                meta: { title: '购销合同关键点', keepAlive: false }
              },
              {
                path: '/trade/sell_contract/:sell_contract_id/attachment',
                name: 'SellContractAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/sell_contract/attachment/Attachment'),
                meta: { title: '购销合同附件', keepAlive: false }
              },
              {
                path: '/trade/sell_contract/:sell_contract_id/attachment/:attachment_id',
                name: 'SellContractAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/sell_contract/attachment/table/Edit'),
                meta: { title: '购销合同附件', keepAlive: false }
              },
              {
                path: '/trade/contract',
                name: 'Contract',
                component: () => import('@/views/a-trade/contract/Contract'),
                meta: { title: '普通合同' }
              },
              {
                path: '/trade/contract/:id',
                name: 'ContractEdit',
                hidden: true,
                component: () => import('@/views/a-trade/contract/table/Edit'),
                meta: { title: '普通合同' }
              },
              {
                path: '/trade/contract/:contract_id/attachment',
                name: 'ContractAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/contract/attachment/Attachment'),
                meta: { title: '普通合同附件', keepAlive: false }
              },
              {
                path: '/trade/contract/:contract_id/attachment/:attachment_id',
                name: 'ContractAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/contract/attachment/table/Edit'),
                meta: { title: '普通合同附件', keepAlive: false }
              },
              {
                path: '/trade/project_material',
                name: 'ProjectMaterial',
                component: () => import('@/views/a-trade/project/material/Material'),
                meta: { title: '项目资料', keepAlive: false }
              },
              {
                path: '/trade/project_material/:material_id',
                name: 'ProjectMaterial',
                hidden: true,
                component: () => import('@/views/a-trade/project/material/table/Edit'),
                meta: { title: '项目资料', keepAlive: false }
              },
              {
                path: '/trade/project_material/:material_id/attachment',
                name: 'ProjectMaterialAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/project/material/attachment/Attachment'),
                meta: { title: '项目资料附件', keepAlive: false }
              },
              {
                path: '/trade/project_material/:material_id/attachment/:attachment_id',
                name: 'ProjectMaterialAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/project/material/attachment/table/Edit'),
                meta: { title: '项目资料附件', keepAlive: false }
              }
            ]
          },
          {
            path: 'inventory',
            name: 'TradeInventory',
            component: () => import('@/views/a-trade/inventory/Inventory'),
            meta: { title: '库存' }
          },
          {
            path: 'inventory/:id',
            name: 'TradeInventoryEdit',
            hidden: true,
            component: () => import('@/views/a-trade/inventory/table/Edit'),
            meta: { title: '库存', keepAlive: false }
          },
          {
            path: 'inventory/:inventory_id/attachment',
            name: 'InventoryAttachment',
            hidden: true,
            component: () => import('@/views/a-trade/inventory/attachment/Attachment'),
            meta: { title: '库存附件', keepAlive: false }
          },
          {
            path: 'inventory/:inventory_id/attachment/:attachment_id',
            name: 'InventoryAttachment',
            hidden: true,
            component: () => import('@/views/a-trade/inventory/attachment/table/Edit'),
            meta: { title: '库存附件', keepAlive: false }
          },
          {
            path: 'inventory_adjustment',
            name: 'InventoryAdjustmentList',
            hidden: true,
            component: () => import('@/views/a-trade/inventory_adjustment/Adjustment'),
            meta: { title: '库存调整', keepAlive: false }
          },
          {
            path: 'inventory/:inventory_id/adjustment',
            name: 'InventoryAdjustment',
            hidden: true,
            component: () => import('@/views/a-trade/inventory/adjustment/Adjustment'),
            meta: { title: '库存调整', keepAlive: false }
          },
          {
            path: 'inventory/:inventory_id/adjustment/:adjustment_id',
            name: 'InventoryAdjustmentEdit',
            hidden: true,
            component: () => import('@/views/a-trade/inventory/adjustment/table/Edit'),
            meta: { title: '库存调整', keepAlive: false }
          },
          {
            path: 'inventory/:inventory_id/adjustment/:adjustment_id/attachment',
            name: 'InventoryAdjustmentAttachment',
            hidden: true,
            component: () => import('@/views/a-trade/inventory/adjustment/attachment/Attachment'),
            meta: { title: '库存调整附件', keepAlive: false }
          },
          {
            path: 'inventory/:inventory_id/adjustment/:adjustment_id/attachment/:attachment_id',
            name: 'InventoryAdjustmentAttachmentEdit',
            hidden: true,
            component: () => import('@/views/a-trade/inventory/adjustment/attachment/table/Edit'),
            meta: { title: '库存调整附件', keepAlive: false }
          },
          {
            path: '/trade/finance',
            name: 'TradeFinance',
            redirect: '/trade/payment',
            component: RouteView,
            meta: { title: '财务', keepAlive: true },
            children: [
              {
                path: '/trade/payment',
                name: 'TradePayment',
                component: () => import('@/views/a-trade/payment/Payment'),
                meta: { title: '付款' }
              },
              {
                path: '/trade/payment/:id',
                name: 'TradePaymentEdit',
                hidden: true,
                component: () => import('@/views/a-trade/payment/table/Edit'),
                meta: { title: '付款', keepAlive: false }
              },
              {
                path: '/trade/payment/:payment_id/attachment',
                name: 'PaymentAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/payment/attachment/Attachment'),
                meta: { title: '付款附件', keepAlive: false }
              },
              {
                path: '/trade/payment/:payment_id/attachment/:attachment_id',
                name: 'PaymentAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/payment/attachment/table/Edit'),
                meta: { title: '付款附件', keepAlive: false }
              },
              {
                path: '/trade/collection',
                name: 'TradeCollection',
                component: () => import('@/views/a-trade/collection/Collection'),
                meta: { title: '收款' }
              },
              {
                path: '/trade/collection/:id',
                name: 'TradeCollectionEdit',
                hidden: true,
                component: () => import('@/views/a-trade/collection/table/Edit'),
                meta: { title: '收款', keepAlive: false }
              },
              {
                path: '/trade/collection/:collection_id/attachment',
                name: 'CollectionAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/collection/attachment/Attachment'),
                meta: { title: '收款附件', keepAlive: false }
              },
              {
                path: '/trade/collection/:collection_id/attachment/:attachment_id',
                name: 'CollectionAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/collection/attachment/table/Edit'),
                meta: { title: '收款附件', keepAlive: false }
              },
              {
                path: '/trade/fapiao',
                name: 'TradeFapiao',
                component: () => import('@/views/a-trade/fapiao/Fapiao'),
                meta: { title: '发票' }
              },
              {
                path: '/trade/fapiao/:id',
                name: 'TradeFapiaoEdit',
                hidden: true,
                component: () => import('@/views/a-trade/fapiao/table/Edit'),
                meta: { title: '发票', keepAlive: false }
              },
              {
                path: '/trade/fapiao/:fapiao_id/attachment',
                name: 'FapiaoAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/fapiao/attachment/Attachment'),
                meta: { title: '发票附件', keepAlive: false }
              },
              {
                path: '/trade/fapiao/:fapiao_id/attachment/:attachment_id',
                name: 'FapiaoAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/fapiao/attachment/table/Edit'),
                meta: { title: '发票附件', keepAlive: false }
              },
              {
                path: '/trade/fapiao/:fapiao_id/fapiao_detail_list',
                name: 'TradeFapiaoDetailList',
                hidden: true,
                component: () => import('@/views/a-trade/fapiao/detail/FapiaoDetail'),
                meta: { title: '发票详情' }
              },
              {
                path: '/trade/fapiao_detail/:id',
                name: 'TradeFapiaoDetailEdit',
                hidden: true,
                component: () => import('@/views/a-trade/fapiao/detail/table/Edit'),
                meta: { title: '发票详情', keepAlive: false }
              },
              {
                path: '/trade/credit_letter',
                name: 'CreditLetter',
                component: () => import('@/views/a-trade/credit_letter/CreditLetter'),
                meta: { title: '信用证' }
              },
              {
                path: '/trade/credit_letter/:id',
                name: 'CreditLetterEdit',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter/table/Edit'),
                meta: { title: '信用证', keepAlive: false }
              },
              {
                path: '/trade/credit_letter/:credit_letter_id/attachment',
                name: 'CreditLetterAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter/attachment/Attachment'),
                meta: { title: '信用证附件', keepAlive: false }
              },
              {
                path: '/trade/credit_letter/:credit_letter_id/attachment/:attachment_id',
                name: 'CreditLetterAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter/attachment/table/Edit'),
                meta: { title: '信用证附件', keepAlive: false }
              },
              {
                path: '/trade/credit_letter/:credit_letter_id/detail',
                name: 'TradeCreditLetterDetailList',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter/detail/Detail'),
                meta: { title: '信用证详情' }
              },
              {
                path: '/trade/credit_letter_detail/:id',
                name: 'TradeCreditLetterDetailEdit',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter/detail/table/Edit'),
                meta: { title: '信用证详情', keepAlive: false }
              },
              {
                path: '/trade/credit_letter_repayment',
                name: 'CreditLetterRepayment',
                component: () => import('@/views/a-trade/credit_letter_repayment/CreditLetterRepayment'),
                meta: { title: '信用证还款' }
              },
              {
                path: '/trade/credit_letter_repayment/:id',
                name: 'CreditLetterRepaymentEdit',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter_repayment/table/Edit'),
                meta: { title: '信用证还款', keepAlive: false }
              },
              {
                path: '/trade/credit_letter_repayment/:credit_letter_repayment_id/attachment',
                name: 'CreditLetterRepaymentAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter_repayment/attachment/Attachment'),
                meta: { title: '信用证还款附件', keepAlive: false }
              },
              {
                path: '/trade/credit_letter_repayment/:credit_letter_repayment_id/attachment/:attachment_id',
                name: 'CreditLetterRepaymentAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter_repayment/attachment/table/Edit'),
                meta: { title: '信用证还款附件', keepAlive: false }
              },
              {
                path: '/trade/credit_letter_repayment/:credit_letter_repayment_id/detail',
                name: 'TradeCreditLetterRepaymentDetailList',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter_repayment/detail/Detail'),
                meta: { title: '信用证还款详情' }
              },
              {
                path: '/trade/credit_letter_repayment_detail/:id',
                name: 'TradeCreditLetterRepaymentDetailEdit',
                hidden: true,
                component: () => import('@/views/a-trade/credit_letter_repayment/detail/table/Edit'),
                meta: { title: '信用证还款详情', keepAlive: false }
              },
              {
                path: '/trade/project_cost',
                name: 'ProjectCost',
                component: () => import('@/views/a-trade/cost/Cost'),
                meta: { title: '项目成本', keepAlive: false }
              },
              {
                path: '/trade/project_cost/:cost_id',
                name: 'ProjectCostDetail',
                hidden: true,
                component: () => import('@/views/a-trade/cost/table/Edit'),
                meta: { title: '项目成本', keepAlive: false }
              },
              {
                path: '/trade/project_cost/:cost_id/attachment',
                name: 'ProjectCostAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/cost/attachment/Attachment'),
                meta: { title: '项目成本附件', keepAlive: false }
              },
              {
                path: '/trade/project_cost/:cost_id/attachment/:attachment_id',
                name: 'ProjectCostAttachment',
                hidden: true,
                component: () => import('@/views/a-trade/cost/attachment/table/Edit'),
                meta: { title: '项目成本附件', keepAlive: false }
              }
            ]
          },
          {
            path: '/trade/approval',
            name: 'Approval',
            redirect: '/trade/approval/templates',
            component: RouteView,
            meta: { title: '审批', keepAlive: true, permission: ['trade_approval'] },
            children: [
              {
                path: '/trade/approval/templates',
                name: 'TradeApprovalTemplates',
                component: () => import('@/views/a-trade/approve/admin/FormsPanel'),
                meta: { title: '流程模板', permission: ['trade_approval'] }
              },
              {
                path: '/trade/approval/templates/create',
                name: 'TradeApprovalTemplatesCreate',
                hidden: true,
                component: () => import('@/views/a-trade/approve/admin/FormProcessDesign'),
                meta: { title: '流程模板' }
              },
              {
                path: '/trade/approval/todo',
                name: 'TradeApprovalToDo',
                component: () => import('@/views/a-trade/approve/admin/ShenPiToDoList'),
                meta: { title: '我的审批' }
              },
              {
                path: '/trade/approval/todo/:id',
                name: 'TradeApprovalToDoDetail',
                hidden: true,
                component: () => import('@/views/a-trade/approve/workspace/process/ProcessInstanceTabs'),
                meta: { title: '审批详情' }
              },
              {
                path: '/trade/approval/todo_all',
                name: 'TradeApprovalTodoAll',
                component: () => import('@/views/a-trade/approve/admin/ShenPiToDoAllList'),
                meta: { title: '全部审批' }
              },
              {
                path: '/trade/approval/todo_bill/:id',
                name: 'TradeApprovalToDoBill',
                hidden: true,
                component: () => import('@/views/a-trade/approve/admin/ShenPiToDoOneBill'),
                meta: { title: '审批记录' }
              },
              {
                path: '/trade/approval/todo_detail/',
                name: 'TradeApprovalDetail',
                hidden: true,
                component: () => import('@/views/a-trade/approve/admin/detail/Detail'),
                meta: { title: '审批内容详情' }
              }
            ]
          }
        ]
      },
      {
        path: '/oa',
        component: PageView,
        redirect: '/oa/user',
        name: 'OA',
        meta: {
          title: 'OA',
          icon: 'apartment',
          keepAlive: true,
          permission: ['oa_user', 'oa_contract', 'oa_department', 'oa_role', 'oa_role_category']
        },
        children: [
          {
            path: '/oa/user',
            name: 'OAUser',
            component: () => import('@/views/a-oa/user/User'),
            meta: { title: '用户', keepAlive: true, permission: ['oa_user'] }
          },
          {
            path: '/oa/contract',
            name: 'OAContract',
            component: () => import('@/views/a-oa/contract/Contract'),
            meta: { title: '合同', keepAlive: true, permission: ['oa_contract'] }
          },
          {
            path: '/oa/department',
            name: 'OADepartment',
            component: () => import('@/views/a-oa/department/Department'),
            meta: { title: '部门', keepAlive: true, permission: ['oa_department'] }
          },
          {
            path: '/oa/role',
            name: 'OARole',
            component: () => import('@/views/a-oa/role/Role'),
            meta: { title: '角色', keepAlive: true, permission: ['oa_role'] }
          },
          {
            path: '/oa/role_category',
            name: 'OARoleCategory',
            component: () => import('@/views/a-oa/rolecategory/Category'),
            meta: { title: '角色分类', keepAlive: true, permission: ['oa_role_category'] }
          }
        ]
      },
      {
        path: '/tools',
        component: PageView,
        redirect: '/tools/tool',
        name: 'tools',
        meta: { title: '工具', icon: 'tool', keepAlive: true, permission: ['tools'] },
        children: [
          {
            path: '/tools/tool/upload',
            name: 'Upload',
            component: () => import('@/views/a-tools/Upload'),
            meta: { title: '上传文件' }
          },
          {
            path: '/tools/unit',
            name: 'Units',
            component: () => import('@/views/a-tools/unit/CommonUnit'),
            meta: { title: '单位' }
          },
          {
            path: '/tools/country',
            name: 'Countries',
            component: () => import('@/views/a-tools/country/CommonCountry'),
            meta: { title: '国家' }
          },
          {
            path: '/tools/currency',
            name: 'Currencies',
            component: () => import('@/views/a-tools/currency/CommonCurrency'),
            meta: { title: '货币' }
          }
        ]
      },
      {
        path: '/data',
        component: PageView,
        redirect: '/data/operate',
        name: 'data',
        meta: { title: '资料', icon: 'file', keepAlive: true, permission: ['data'] },
        children: [
          {
            path: 'operate',
            name: 'OperateFile',
            component: () => import('@/views/a-data/OperateData'),
            meta: { title: '运营资料' }
          },
          {
            path: 'tech',
            name: 'TechFile',
            component: () => import('@/views/a-data/TechData'),
            meta: { title: '技术资料' }
          },
          {
            path: 'od',
            name: 'OD',
            component: () => import('@/views/a-data/DocumentForm'),
            meta: { title: '官方文件' }
          }
        ]
      },
      {
        path: '/account/settings',
        component: PageView,
        redirect: '/account/settings/base',
        hidden: true,
        name: 'AccountSetting',
        meta: { title: '个人页', icon: 'user', keepAlive: true, permission: [ 'user' ] },
        children: [
          {
            path: '/account/settings/base',
            name: 'BaseSettings',
            component: () => import('@/views/account/settings/BaseSetting'),
            meta: { title: '基本设置', hidden: true, permission: [ 'user' ] }
          }
        ]
      }
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }

]

export const Dictionaries = {
  TemporaryCarAction: {
    1: '南门进区',
    2: '南门出区',
    3: '西门进区',
    4: '西门出区'
  },
  NotificationType: {
    1: '便捷出入区申请'
  },
  shelf_life_unit: {
    1: '天',
    2: '月',
    3: '年'
  },
  wxcodeStatus: {
    1: '上传中',
    2: '上传失败',
    3: '待审核',
    4: '审核中',
    5: '审核失败',
    6: '审核成功',
    7: '审核延后'

  },
  commodity_purchase_status: {
    1: '创建',
    2: '接收',
    3: '上架'
  },
  trans_mode: {
    '0': '非保税区',
    '1': '监管仓库',
    '2': '水路运输',
    '3': '铁路运输',
    '4': '公路运输',
    '5': '航空运输',
    '6': '邮件运输',
    '7': '保税区',
    '8': '保税仓库',
    '9': '其它运输',
    'A': '全部运输方式',
    'H': '边境特殊海关作业区',
    'W': '物流中心',
    'X': '物流园区',
    'Y': '保税港区',
    'Z': '出口加工区'
  },
  pick_status: {
    1: '未指定',
    2: '已指定',
    3: '已分拣',
    4: '已打包',
    5: '已完成'
    // 5: '海关审查中',
    // 7: '已拦截',
    // 8: '已取消',
    // 9: '一般商品已发货'
  },
  logistics_company: {
    1: '未知',
    2: '圆通'
  },
  outflow_status: {
    1: '创建',
    2: '完成',
    3: '取消'
  },
  wechat_order_status: {
    1: '创建',
    2: '同步',
    3: '完成',
    4: '失败'
  },
  transfer_inventory_status: {
    1: '创建',
    2: '出库',
    3: '入库'
  },
  intercept_order_status: {
    1: '创建',
    2: '完成',
    3: '拦截失败'
  },
  status: [
    { 1: '待入园' },
    { 2: '已入园' },
    { 3: '已注销' }
  ],
  RecordAction: {
    1: '创建',
    2: '删除'
  },
  CorporationLocation: {
    1: '北',
    2: '南'
  },
  GoodsType: {
    1: '一般商品',
    2: '跨境电商',
    3: '以上两者'
  },
  mallshop_order_status: {
    1: '待支付',
    2: '待发货',
    3: '待收货',
    4: '待评价',
    5: '已取消',
    6: '系统取消',
    7: '退款中',
    8: '已退款',
    9: '已支付，库存不足'
  },
  location: [
    { 1: '北区' },
    { 2: '南区' },
    { 3: '其他' }
  ],
  rolesObj: {
    1: '管理端',
    2: '企业端',
    3: '物业端',
    4: '协管端'
  },
  roles: [
    { 1: '管理员' },
    { 2: '企业' },
    { 3: '物业' },
    { 4: '协管' }
  ],
  ContractStatus: {
    1: '已创建',
    2: '已签约',
    3: '已废除',
    4: '已完成'
  },
  BillStatus: {
    1: '待支付',
    2: '已支付',
    3: '已取消',
    4: '已完成',
    6: '未生成审批',
    7: '审批中',
    8: '审批失败'
  },
  BillCreateMode: {
    1: '自动',
    2: '按时间',
    3: '手动',
    4: '减免'
  },
  BillSearchStatus: {
    1: '待支付',
    2: '已支付',
    3: '已取消'
  },
  PaymentTicketStatus: {
    1: '未审核',
    2: '已审核',
    3: '申请发票',
    4: '已完成',
    5: '已拒绝'
  },
  PaymentTicketCategory: {
    1: '物业',
    2: '租金',
    3: '水费',
    4: '电费',
    5: '预付电费',
    6: '保证金',
    10: '系统生成',
    11: '手工生成'
  },
  FapiaoApplicationStatus: {
    1: '待审核',
    2: '已同意',
    3: '已拒绝'
  },
  take_inventory_method: {
    1: '公司盘存',
    2: '仓库盘存'
  },
  take_inventory_status: {
    1: '待确认',
    2: '已确认',
    3: '已取消'
  },
  rent_contract: [
    { en_name: 'corporation', name: '公司' },
    { en_name: 'start_date', name: '开始时间' },
    { en_name: 'end_date', name: '结束时间' },
    { en_name: 'deposit', name: '押金' },
    { en_name: 'year_increase', name: '年增长百分比' },
    { en_name: 'number', name: '合同编号' },
    { en_name: 'address', name: '企业注册地址' },
    { en_name: 'rent_address', name: '企业租赁地址' },
    { en_name: 'in_charge_person', name: '负责人' },
    { en_name: 'contact_person', name: '乙方联系人' },
    { en_name: 'contact_phone', name: '乙方联系人电话' },
    { en_name: 'a_contact_person', name: '甲方联系人' },
    { en_name: 'a_contact_phone', name: '甲方联系人电话' }
  ],
  GateWayStatus: {
    1: '已创建',
    2: '已核准',
    3: '已进区',
    4: '已出区',
    5: '已取消'
  },
  GateWayItem: [
    {
      key: 1,
      label: '固定资产类',
      value: 1,
      children: [
        {
          label: '仪器、工具、机器/设备',
          value: 1,
          key: 1
        },
        {
          label: '办公类',
          value: 2,
          key: 2
        }
      ]
    },
    {
      key: 2,
      value: 2,
      label: '消耗品类',
      children: [
        {
          label: '自用办公耗材',
          value: 11,
          key: 11
        },
        {
          label: '生产用物料',
          value: 12,
          key: 12
        },
        {
          label: '劳保',
          value: 13,
          key: 13
        },
        {
          label: '生活消费',
          value: 14,
          key: 14
        }
      ]
    },
    {
      key: 3,
      value: 3,
      label: '基建设备物品类',
      children: [
        {
          label: '机器',
          value: 21,
          key: 21
        },
        {
          label: '设备',
          value: 22,
          key: 22
        },
        {
          label: '物资',
          value: 23,
          key: 23
        }
      ]
    },
    {
      key: 4,
      value: 4,
      label: '其他',
      children: [
        {
          label: '其他',
          value: 31,
          key: 31
        }
      ]
    }
  ],
  Category: {
    1: '固定资产类',
    2: '消耗品类',
    3: '基建设备物品类',
    4: '其他',
    5: '渣土'
  },
  Subcategory: {
    1: '仪器、工具、机器/设备',
    2: '办公类',
    11: '自用办公耗材',
    12: '生产用物料',
    13: '劳保',
    14: '生活消费',
    21: '机器',
    22: '设备',
    23: '物资',
    31: '其他',
    41: '渣土'
  },
  returnCode: {
    1000: '成功请求并返回数据',
    2000: '参数不足',
    2001: '参数验证失败',
    3000: '数据异常',
    4000: '对象不存在',
    4001: '用户名重复',
    4002: '用户名或密码错误',
    4003: '状态错误',
    4004: '有子资源',
    4005: '数据重复',
    4006: '获取微信 openid 错误',
    4007: '解密数据失败',
    4008: '第三方服务错误',
    4009: '密码错误',
    4010: '账单已存在',
    4011: '配置项没有打开',
    4012: '库存不足',
    4013: '数量限制',
    4014: '记录存在',
    4015: '微信错误',
    4016: '微信凭证错误',
    4017: '展示商品金额错误',
    4018: '展示商品退回路径错误',
    4019: '商品计算库存错误',
    4020: '商品重复退款',
    4021: '等待浪潮审批',
    4022: '浪潮驳回'
  },
  property_owner: {
    // 1: '自持',
    // 2: '代理'
    1: '自营',
    2: '管委会',
    3: '济高'
  },
  account_owner: {
    1: '自营',
    2: '管委会',
    3: '济高',
    4: '保证金'
  },
  property_owner_account: {
    1: '86611778101421004588',
    2: '86611778101421004571',
    3: '86611029101421026547',
    4: '86611731101421004169'
  },
  property_type: {
    1: '工厂',
    2: '土地',
    3: '仓库',
    4: '办公'
  },
  unit: { },
  country: { },
  currency: { },
  CustomsPaymentCheckStatus: {
    1: '已创建',
    2: '已完成',
    3: '失败'
  },
  is_pushed: {
    0: '已推送',
    1: '未推送'
  },
  is_auto_pushed: {
    1: '是',
    0: '否'
  },
  can_pushed: {
    1: '是',
    0: '否'
  },
  is_union: {
    true: '是',
    false: '否'
  },
  order_return_status: {
    '1': '电子口岸已暂存',
    '2': '电子口岸申报中',
    '3': '发送海关成功',
    '4': '发送海关失败',
    '100': '海关退单',
    '120': '海关入库',
    '300': '人工审核',
    '399': '海关审结',
    '800': '放行',
    '899': '结关',
    '500': '查验',
    '501': '扣留移送通关',
    '502': '扣留移送缉私',
    '503': '扣留移送法规',
    '599': '其它扣留',
    '700': '退运'
  },
  locations: {
    'north': '北区',
    'south': '南区',
    'other': '其他'
  },
  locations_type: {
    'factory': '厂房',
    'office': '办公室',
    'land': '地皮',
    'warehouse': '仓库'
  },
  electricity_meter_property_owner: {
    1: '自营',
    2: '管委会',
    3: '济高'
  },
  applet_order_status: {
    1: '待付款',
    2: '待发货',
    3: '已发货',
    4: '已收货',
    5: '已取消',
    6: '系统取消',
    7: '退款中',
    8: '退款完成',
    11: '部分退款'
  },
  delivery_method: {
    1: '快递',
    2: '自提'
  },
  declaration_status: {
    1: '未申报',
    2: '检查中',
    3: '已通过'
  },
  pick_list_order_source: {
    1: '微信',
    2: '柜台'
  },
  applet_order_exam_status_text: {
    2: '待审核',
    3: '通过',
    4: '驳回'
  },
  applet_order_exam_status: {
    3: '通过',
    4: '驳回'
  },
  refund_order_exam_status: {
    3: '通过',
    4: '驳回'
  },
  goods_type: {
    1: '一般商品',
    2: '跨境商品'
  },
  integrity_refund_goods: {
    2: '校验商品完整性',
    3: '完好',
    4: '不完好'
  },
  refund_goods_status: {
    1: '未处理',
    2: '已处理'
  },
  order_source: {
    1: '线上',
    2: '线下'
  },
  open_consumption_tax: {
    1: '关闭',
    2: '开启'
  },
  consumption_tax_unit: {
    1: '法定单位1',
    2: '法定单位2'
  },
  property_project_check_status: {
    1: '创建',
    2: '审批中',
    3: '完成',
    4: '失败'
  },
  property_project_category: {
    1: '用印',
    2: '付款',
    3: '报销',
    4: '冲销'
  },
  property_project_fee_category: {
    1: '员工',
    2: '供应商'
  },
  property_project_fee_method: {
    1: '现金',
    2: '银行转账'
  },
  property_project_fee_type: {
    1: '物业费',
    2: '水费',
    3: '电费',
    4: '其他'
  },
  property_project_seal_type: [
    '公章',
    '法人名章',
    '合同章',
    '中英文章',
    '党支部章',
    '财务章',
    '提货专用章',
    '其它'
  ],
  property_project_fee_subject: {
    1: '物业公司服务费',
    2: '园区改造修理修缮',
    3: '园区日常运营支出',
    4: '园区电费支出',
    5: '园区用水支出',
    6: '其他'
  },
  business_type: {
    'water_bill': '水费账单',
    'electricity_bill': '电费账单',
    'rent_bill': '租金账单',
    'property_bill': '物业账单',
    'contract_bill': '合同',
    'guarantee_bill': '保证金账单'
  },
  approval_result_status: {
    0: '待审批',
    1: '已审批'
  },
  public_area_fee_type: {
    1: '水费',
    2: '电费'
  },
  approval_result: {
    1: '待审批',
    2: '通过',
    3: '驳回'
  },
  coupon_category: {
    1: '全场通用',
    2: '商品分类',
    3: '商品'
  },
  duration_type: {
    1: '天',
    2: '截至日期'
  },
  refund_order_status: {
    1: '申请退款',
    2: '同意退款',
    3: '用户已发出商品',
    4: '完成退款',
    5: '拒绝退款',
    6: '退款失败'
  },
  goods_sell_type: {
    1: '自营',
    2: '代销'
  },
  value_card_status: {
    1: '已创建',
    2: '已销售',
    3: '已开票'
  },
  admin_permission_group: {
    'property': '资产',
    'finance': '财务',
    'user': '用户',
    'corporation': '企业',
    'tools': '工具',
    'data': '资料',
    'warehouse_shop': '仓库/门店',
    'oa': 'OA',
    'null': '其他'
  },
  corporation_permission_group: {
    'in_out': '进出区管理',
    'contract': '合同',
    'bill': '账单',
    'finance': '财务',
    'basic': '基础',
    'user': '用户',
    'bonded': '保税',
    'show': '展示',
    'customs_declaration': '报关',
    'config': '配置',
    'null': '其他'
  },
  trade_payment_method: {
    1: '电汇',
    2: '银行承兑',
    3: '信用证',
    4: '商业承兑',
    5: '福费廷'
  },
  trade_cost_type: {
    1: '电汇',
    2: '银行承兑',
    3: '商业承兑',
    4: '福费廷',
    5: '押汇'
  },
  trade_fapiao_method: {
    1: '开票',
    2: '收票'
  },
  trade_approve_type: {
    'trade_sell_contract': '购销合同',
    'trade_contract': '普通合同',
    'trade_inventory': '出入库',
    'trade_inventory_adjustment': '出入库调整',
    'trade_payment': '付款',
    'trade_collection': '收款',
    'trade_fapiao': '发票',
    'trade_credit_letter': '信用证',
    'trade_credit_letter_repayment': '信用证还款'
  },
  trade_material_type: {
    1: '货物',
    2: '物流',
    3: '资金',
    4: '其他'
  }
}
